const $$items = $($('.js-about-item').get().reverse());

const isActive = $elem => {
  const windowHeight = $(window).height();
  const windowTop = $(window).scrollTop();
  const elHeight = $elem.outerHeight();
  const elTop = $elem.offset().top;

  const condition = windowHeight - (elTop - windowTop) > elHeight * 2;

  return condition;
};

const abouts = () => {



  const checkAbouts = () => {

    let activeFound = false;

    $$items.each((i, el) => {

      if (!activeFound && isActive($(el))) {
        activeFound = true;
        $(el).addClass('is-active');

      } else {
        $(el).removeClass('is-active');
      }

    });
  };

  checkAbouts();

  $(window).on('scroll', checkAbouts);
  $(window).on('resize', checkAbouts);

};

export default abouts;
