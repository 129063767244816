const $heroInner = $('.js-hero-inner');

const scroll = () => {

  $(window).on('scroll', () => {
    if ($(window).scrollTop() > 0.5 * $(window).height()) {
      $heroInner.addClass('is-hidden');
    } else {
      $heroInner.removeClass('is-hidden');
    }
  });

  const makeScroll = (pos) => {
    $('html, body').animate({
      scrollTop: pos
    }, 1000);
  };

  $('.js-scroll-down').each((i, el) => {
    $(el).click(event => {
      event.preventDefault();
      const $target = $(el).closest('.js-section').next('.js-section');
      if (!$target.length) return;
      makeScroll($target.offset().top);
    });
  });

  $('.js-scroll-to').each((i, el) => {

    $(el).click(event => {
      event.preventDefault();
      const $target = $($(el).attr('href'));
      console.log($target);
      if (!$target.length) return;
      makeScroll($target.offset().top);
      $('.js-menu').addClass('is-hidden');
    });
  });

  $('.js-scroll-top').each((i, el) => {
    $(el).click(event => {
      event.preventDefault();
      makeScroll(0);
    });
  });
};

export default scroll;
