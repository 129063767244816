export default function() {
  function calcViewportUnits() {
    const w = Math.min(document.documentElement.clientWidth, window.innerWidth || 0);
    const h = Math.min(document.documentElement.clientHeight, window.innerHeight || 0);

    const vh = h * 0.01;
    const vw = w * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  }
  calcViewportUnits();
  $(window).on('resize', calcViewportUnits);
}
