import sayHello from './lib/sayHello';
import vh from './_vh';
import scroll from './_scroll';
import menu from './_menu';
import abouts from './_abouts';

sayHello();

$(() => {
  vh();
  scroll();
  menu();
  abouts();
});
