const $menuItems = $('.js-menu');
const $menuBtn = $('.js-menu-btn');

const menu = () => {
  $menuBtn.click(event => {
    event.preventDefault();
    $menuItems.toggleClass('is-hidden');
	});

	$('body').click(event => {
    const $target = $(event.target);

		if (!$target.closest($menuBtn[0]).length &&
		!$target.closest($menuItems[0]).length
		) {
			$menuItems.addClass('is-hidden');
		}

	});
};

export default menu;
